<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleSearchCreate
          v-if="isOwner"
          :page-title="!$language ? 'Batches' : 'ব্যাচ সমূহ'"
          :placeholder="
            !$language ? 'Search By Batch Name' : 'ব্যাচ নাম দিয়ে সার্চ করুন'
          "
          :create-btn="!$language ? 'Create Batch' : 'ব্যাচ তৈরি করুন'"
          @createEvent="$router.push('batches/create')"
        ></PageTitleSearchCreate>
        <PageTitle
          v-else
          :page-title="!$language ? 'Batches' : 'ব্যাচ সমূহ'"
          class="mb-10"
        />
      </v-col>
      <template v-if="fetchingBatches">
        <div id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      </template>
      <no-content-component
        v-else-if="noContentCondition"
        :message="noContentMessage"
      ></no-content-component>
      <v-col
        v-else
        v-for="batch in batches"
        :key="batch.batch_id"
        xl="4"
        lg="6"
        md="12"
        sm="12"
        class="m-4"
      >
        <MBatchCard :batch="batch" @delete="showDeleteBatchDialog"></MBatchCard>
      </v-col>
    </v-row>
    <dialog-delete
      v-model="batchDeleteDialog"
      :deleting="deleting"
      :item="batchToDelete"
      :item-text="!$language ? 'Batch' : 'ব্যাচ'"
      @confirm-remove="removeBatch"
    ></dialog-delete>
  </v-container>
</template>

<script>
import PageTitleSearchCreate from "@ecf/global/components/PageTitleSearchCreate";
import DialogDelete from "@ecf/global/components/DialogDelete";
import MBatchCard from "#ecf/batch/components/MBatchCard";
import batchService from "#ecf/batch/BatchApi";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "BatchListPage",
  components: {
    DialogDelete,
    MBatchCard,
    PageTitleSearchCreate,
    NoContentComponent,
  },
  props: {
    courseId: String,
  },
  data() {
    return {
      batchesData: [],
      fetchingBatches: false,
      batchDeleteDialog: false,
      batchToDelete: undefined,
      deleting: false,
    };
  },
  computed: {
    batches() {
      return this.$store.getters["batch/batches"][this.courseId];
    },
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    },
    noContentCondition() {
      return this.batches?.length === 0;
    },
    noContentMessage() {
      return !this.$language ? "You don't have any Batch" : "আপনার কোনো ব্যাচ নেই";
    },
  },
  created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    this.fetchBatches();
  },
  methods: {
    async fetchBatches() {
      try {
        this.fetchingBatches = true;
        await this.$store.dispatch("batch/list", {
          courseId: this.courseId,
          partner_code:
            this.$store.state.organization.currentOrganization.partner_code,
        });
        this.fetchingBatches = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    showDeleteBatchDialog(batch) {
      this.batchDeleteDialog = true;
      this.batchToDelete = batch;
    },
    async removeBatch() {
      if (this.deleting) return;
      try {
        this.deleting = true;
        await batchService.delete(this.batchToDelete, this.courseId);
        await this.$store.dispatch("batch/list", {
          courseId: this.courseId,
          partner_code:
            this.$store.state.organization.currentOrganization.partner_code,
          refresh: true,
        });
        this.hideDeleteDialog();
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    },
    hideDeleteDialog() {
      this.batchDeleteDialog = false;
    },
  },
};
</script>
