<template>
  <div>
    <v-card class="mx-auto batch-card" max-width="550">
      <div class="height-70">
        <v-row class="pa-0 row-section-1">
          <v-col cols="10" class="pa-0 ma-0">
            <v-card-title
              class="pa-0 ma-0 batch-title custom-font-family text-truncate"
            >
              {{ `${batch.batch_title}` }}
              <span class="pl-1 batch-type">({{ batchType }})</span>
            </v-card-title>
            <v-card-subtitle
              class="mb-0 mx-0 pa-0 batch-teacher-name custom-font-family text-truncate"
            >
              By {{ batch.teacher_name }}
            </v-card-subtitle>
          </v-col>
          <v-col cols="2" class="pa-0 ma-0 batch-dropdown-section">
            <div v-if="isOwner" class="text-center d">
              <v-menu offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="black" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    class="batch-card-menu-list-item clickable"
                    @click="editHandler()"
                  >
                    <v-list-item-title class="subtitle-2 custom-font-family">
                      <v-icon>fas fa-list</v-icon>
                      {{
                        !$language ? "Edit Batch" : "ব্যাচ এডিট করুন"
                      }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                    class="batch-card-menu-list-item clickable"
                    @click="deleteHandler()"
                  >
                    <v-list-item-title class="subtitle-2 custom-font-family">
                      <v-icon>fas fa-list</v-icon
                      >{{
                        !$language ? "Delete Batch" : "ব্যাচ ডিলিট করুন"
                      }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
        <v-divider class="divider"></v-divider>
      </div>

      <div class="height-160">
        <v-row class="mb-0 mx-0 pa-0 row-section-2">
          <v-col cols="8" class="ma-0 py-0 pr-0 batch-start-end-date-section">
            <v-row class="ma-0 pa-0">
              <v-col cols="2" class="ma-0 pa-0">
                <img
                  class="batch-start-end-date-icon"
                  src="../assets/B_D_Date.svg"
                  alt=""
                />
              </v-col>
              <v-col cols="10" class="ma-0 pa-0">
                <v-card-subtitle
                  class="custom-font-family batch-start-end-date ma-0 py-0 pr-0"
                  style="padding-left: 10px"
                >
                  {{
                    !$language
                      ? formatDate(batch.begin_date)
                      : new Date(batch.begin_date).toLocaleString("bn-BD", {
                          dateStyle: "medium",
                        })
                  }}
                  -
                  {{
                    !$language
                      ? formatDate(batch.end_date)
                      : new Date(batch.end_date).toLocaleString("bn-BD", {
                          dateStyle: "medium",
                        })
                  }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="4" class="ma-0 pa-0 batch-total-student-section">
            <v-row class="ma-0 pa-0">
              <v-col
                cols="12"
                class="ma-0 pa-0"
                style="
                  display: flex;
                  padding-right: 30px !important;
                  justify-content: end;
                "
              >
                <img
                  class="batch-total-student-icon"
                  src="../assets/B_N_Students.svg"
                  alt=""
                />
                <v-card-subtitle
                  class="custom-font-family batch-total-student text-truncate my-0 mr-0 pa-0"
                >
                  <b>{{ batch.num_students }}</b>
                  {{ !$language ? "Students" : "জন শিক্ষার্থী" }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mb-0 pa-0 row-section-3">
          <v-col cols="12" class="ma-0 pa-0 calender-days-icon-section">
            <v-row class="ma-0 pa-0">
              <v-col cols="2" class="ma-0 pa-0">
                <img
                  src="../assets/B_S_Weekly.svg"
                  class="calender-icon"
                  alt=""
                />
              </v-col>
              <v-col cols="10" class="days-icons mt-0 mr-0 mb-0 pa-0">
                <v-chip
                  class="days-icon"
                  color="#0099DC"
                  :outlined="hasBatchClass('Saturday')"
                  dark
                  @mouseover="showClassTime('Saturday:')"
                  @mouseout="hideClassTime()"
                  >{{ !$language ? "S" : "শ" }}</v-chip
                >
                <v-chip
                  class="days-icon"
                  color="#0099DC"
                  :outlined="hasBatchClass('Sunday')"
                  dark
                  @mouseover="showClassTime('Sunday:')"
                  @mouseout="hideClassTime()"
                  >{{ !$language ? "S" : "র" }}</v-chip
                >
                <v-chip
                  class="days-icon"
                  color="#0099DC"
                  :outlined="hasBatchClass('Monday')"
                  dark
                  @mouseover="showClassTime('Monday:')"
                  @mouseout="hideClassTime()"
                  >{{ !$language ? "M" : "সো" }}</v-chip
                >
                <v-chip
                  class="days-icon"
                  color="#0099DC"
                  :outlined="hasBatchClass('Tuesday')"
                  dark
                  @mouseover="showClassTime('Tuesday:')"
                  @mouseout="hideClassTime()"
                  >{{ !$language ? "T" : "ম" }}</v-chip
                >
                <v-chip
                  class="days-icon"
                  color="#0099DC"
                  :outlined="hasBatchClass('Wednesday')"
                  dark
                  @mouseover="showClassTime('Wednesday:')"
                  @mouseout="hideClassTime()"
                  >{{ !$language ? "W" : "বু" }}</v-chip
                >
                <v-chip
                  class="days-icon"
                  color="#0099DC"
                  :outlined="hasBatchClass('Thursday')"
                  dark
                  @mouseover="showClassTime('Thursday:')"
                  @mouseout="hideClassTime()"
                  >{{ !$language ? "T" : "বৃ" }}</v-chip
                >
                <v-chip
                  class="days-icon"
                  color="#0099DC"
                  :outlined="hasBatchClass('Friday')"
                  dark
                  @mouseover="showClassTime('Friday:')"
                  @mouseout="hideClassTime()"
                  >{{ !$language ? "F" : "শু" }}</v-chip
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pr-5 pl-7 row-section-4" no-gutters>
          <v-col cols="7" class="ma-0 pa-0 batch-class-start-end-time-section">
            <v-row v-if="shouldShowClassTime" class="ma-0 pa-0">
              <v-col cols="2" class="ma-0 pa-0">
                <img
                  src="../assets/B_D_Time.svg"
                  class="batch-class-start-end-time-icon"
                  alt=""
                />
              </v-col>
              <v-col cols="10" class="ma-0 pa-0">
                <v-card-subtitle
                  class="custom-font-family batch-class-start-end-time mt-0 mr-0 mb-0 pa-0"
                >
                  {{ time }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="mb-0 pa-0 detail-btn-section">
            <v-btn
              depressed
              tile
              plain
              class="custom-font-family"
              @click="
                $router.push({
                  name: 'batch-management',
                  params: {
                    batchId: batch.batch_id,
                  },
                })
              "
            >
              {{ !$language ? "Details" : "বিস্তারিত" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import { months, suffixes, formatAMPM } from "/global/utils/helpers";
export default {
  name: "MBatchCard",
  props: {
    batch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shouldShowClassTime: false,
      classTimes: new Map([
        ["saturday:", "No Class"],
        ["sunday:", "No Class"],
        ["monday:", "No Class"],
        ["tuesday:", "No Class"],
        ["wednesday:", "No Class"],
        ["thursday:", "No Class"],
        ["friday:", "No Class"],
      ]),
      time: "",
    };
  },
  computed: {
    batchType() {
      if (this.$language) {
        if (this.batch.batch_type == "Online") {
          return "অনলাইন";
        } else if (this.batch.batch_type == "In Person") {
          return "ব্যক্তিগত ভাবে";
        }
      }
      return this.batch.batch_type;
    },
    currentOrganization() {
      return this.$store.state.organization.currentOrganization;
    },
    isOwner() {
      return this.$store.state.user.user.access[
        this.currentOrganization.partner_code
      ].includes("owner");
    },
  },
  created() {
    this.makeClassTimeMap(this.batch.days);
  },
  methods: {
    hasBatchClass(input_day) {
      for (let i = 0; i < this.batch.days.length; i++) {
        if (this.batch.days[i].toLowerCase().includes(input_day.toLowerCase()))
          return false;
      }
      return true;
    },

    showClassTime(day) {
      this.time = this.classTimes.get(day.toLowerCase());
      this.shouldShowClassTime = true;
    },

    hideClassTime() {
      this.shouldShowClassTime = false;
    },

    makeClassTimeMap(days) {
      for (let i = 0; i < days.length; i++) {
        let day = days[i].split(" ")[0];
        let times = days[i].split(" ")[1];
        let start_time = times.split("-")[0];
        let end_time = times.split("-")[1];
        start_time = formatAMPM(
          start_time.split(":")[0],
          start_time.split(":")[1]
        );
        end_time = formatAMPM(end_time.split(":")[0], end_time.split(":")[1]);
        this.classTimes.set(day.toLowerCase(), start_time + " - " + end_time);
      }
    },

    formatDate(date) {
      date = new Date(date);
      return (
        suffixes[date.getDate()] +
        " " +
        months[date.getMonth()] +
        " " +
        date.getFullYear()
      );
    },

    editHandler() {
      this.$router.push({
        name: "batch-management",
        params: {
          batchId: this.batch.batch_id,
          directEdit: true,
        },
      });
    },

    deleteHandler() {
      if (this.isOwner) {
        this.$emit("delete", this.batch);
      } else {
        this.$root.$emit("alert", [
          undefined,
          "Only Batch Owners are allowed to delete",
        ]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "#ecf/batch/styles/MBatchCard.scss";
</style>
