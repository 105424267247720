var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-auto batch-card", attrs: { "max-width": "550" } },
        [
          _c(
            "div",
            { staticClass: "height-70" },
            [
              _c(
                "v-row",
                { staticClass: "pa-0 row-section-1" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-0 ma-0", attrs: { cols: "10" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "pa-0 ma-0 batch-title custom-font-family text-truncate",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(`${_vm.batch.batch_title}`) + " "
                          ),
                          _c("span", { staticClass: "pl-1 batch-type" }, [
                            _vm._v("(" + _vm._s(_vm.batchType) + ")"),
                          ]),
                        ]
                      ),
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "mb-0 mx-0 pa-0 batch-teacher-name custom-font-family text-truncate",
                        },
                        [_vm._v(" By " + _vm._s(_vm.batch.teacher_name) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 ma-0 batch-dropdown-section",
                      attrs: { cols: "2" },
                    },
                    [
                      _vm.isOwner
                        ? _c(
                            "div",
                            { staticClass: "text-center d" },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "", left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color: "black",
                                                      icon: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4082850938
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass:
                                            "batch-card-menu-list-item clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editHandler()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "subtitle-2 custom-font-family",
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-list"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    !_vm.$language
                                                      ? "Edit Batch"
                                                      : "ব্যাচ এডিট করুন"
                                                  )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          staticClass:
                                            "batch-card-menu-list-item clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteHandler()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "subtitle-2 custom-font-family",
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-list"),
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  !_vm.$language
                                                    ? "Delete Batch"
                                                    : "ব্যাচ ডিলিট করুন"
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "divider" }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "height-160" },
            [
              _c(
                "v-row",
                { staticClass: "mb-0 mx-0 pa-0 row-section-2" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "ma-0 py-0 pr-0 batch-start-end-date-section",
                      attrs: { cols: "8" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ma-0 pa-0", attrs: { cols: "2" } },
                            [
                              _c("img", {
                                staticClass: "batch-start-end-date-icon",
                                attrs: {
                                  src: require("../assets/B_D_Date.svg"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "ma-0 pa-0", attrs: { cols: "10" } },
                            [
                              _c(
                                "v-card-subtitle",
                                {
                                  staticClass:
                                    "custom-font-family batch-start-end-date ma-0 py-0 pr-0",
                                  staticStyle: { "padding-left": "10px" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.$language
                                          ? _vm.formatDate(_vm.batch.begin_date)
                                          : new Date(
                                              _vm.batch.begin_date
                                            ).toLocaleString("bn-BD", {
                                              dateStyle: "medium",
                                            })
                                      ) +
                                      " - " +
                                      _vm._s(
                                        !_vm.$language
                                          ? _vm.formatDate(_vm.batch.end_date)
                                          : new Date(
                                              _vm.batch.end_date
                                            ).toLocaleString("bn-BD", {
                                              dateStyle: "medium",
                                            })
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0 batch-total-student-section",
                      attrs: { cols: "4" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "ma-0 pa-0",
                              staticStyle: {
                                display: "flex",
                                "padding-right": "30px !important",
                                "justify-content": "end",
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c("img", {
                                staticClass: "batch-total-student-icon",
                                attrs: {
                                  src: require("../assets/B_N_Students.svg"),
                                  alt: "",
                                },
                              }),
                              _c(
                                "v-card-subtitle",
                                {
                                  staticClass:
                                    "custom-font-family batch-total-student text-truncate my-0 mr-0 pa-0",
                                },
                                [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.batch.num_students)),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.$language
                                          ? "Students"
                                          : "জন শিক্ষার্থী"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-0 pa-0 row-section-3" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0 calender-days-icon-section",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ma-0 pa-0", attrs: { cols: "2" } },
                            [
                              _c("img", {
                                staticClass: "calender-icon",
                                attrs: {
                                  src: require("../assets/B_S_Weekly.svg"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "days-icons mt-0 mr-0 mb-0 pa-0",
                              attrs: { cols: "10" },
                            },
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "days-icon",
                                  attrs: {
                                    color: "#0099DC",
                                    outlined: _vm.hasBatchClass("Saturday"),
                                    dark: "",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.showClassTime("Saturday:")
                                    },
                                    mouseout: function ($event) {
                                      return _vm.hideClassTime()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(!_vm.$language ? "S" : "শ"))]
                              ),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "days-icon",
                                  attrs: {
                                    color: "#0099DC",
                                    outlined: _vm.hasBatchClass("Sunday"),
                                    dark: "",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.showClassTime("Sunday:")
                                    },
                                    mouseout: function ($event) {
                                      return _vm.hideClassTime()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(!_vm.$language ? "S" : "র"))]
                              ),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "days-icon",
                                  attrs: {
                                    color: "#0099DC",
                                    outlined: _vm.hasBatchClass("Monday"),
                                    dark: "",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.showClassTime("Monday:")
                                    },
                                    mouseout: function ($event) {
                                      return _vm.hideClassTime()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(!_vm.$language ? "M" : "সো"))]
                              ),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "days-icon",
                                  attrs: {
                                    color: "#0099DC",
                                    outlined: _vm.hasBatchClass("Tuesday"),
                                    dark: "",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.showClassTime("Tuesday:")
                                    },
                                    mouseout: function ($event) {
                                      return _vm.hideClassTime()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(!_vm.$language ? "T" : "ম"))]
                              ),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "days-icon",
                                  attrs: {
                                    color: "#0099DC",
                                    outlined: _vm.hasBatchClass("Wednesday"),
                                    dark: "",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.showClassTime("Wednesday:")
                                    },
                                    mouseout: function ($event) {
                                      return _vm.hideClassTime()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(!_vm.$language ? "W" : "বু"))]
                              ),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "days-icon",
                                  attrs: {
                                    color: "#0099DC",
                                    outlined: _vm.hasBatchClass("Thursday"),
                                    dark: "",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.showClassTime("Thursday:")
                                    },
                                    mouseout: function ($event) {
                                      return _vm.hideClassTime()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(!_vm.$language ? "T" : "বৃ"))]
                              ),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "days-icon",
                                  attrs: {
                                    color: "#0099DC",
                                    outlined: _vm.hasBatchClass("Friday"),
                                    dark: "",
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.showClassTime("Friday:")
                                    },
                                    mouseout: function ($event) {
                                      return _vm.hideClassTime()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(!_vm.$language ? "F" : "শু"))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "pr-5 pl-7 row-section-4",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "ma-0 pa-0 batch-class-start-end-time-section",
                      attrs: { cols: "7" },
                    },
                    [
                      _vm.shouldShowClassTime
                        ? _c(
                            "v-row",
                            { staticClass: "ma-0 pa-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "ma-0 pa-0",
                                  attrs: { cols: "2" },
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "batch-class-start-end-time-icon",
                                    attrs: {
                                      src: require("../assets/B_D_Time.svg"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ma-0 pa-0",
                                  attrs: { cols: "10" },
                                },
                                [
                                  _c(
                                    "v-card-subtitle",
                                    {
                                      staticClass:
                                        "custom-font-family batch-class-start-end-time mt-0 mr-0 mb-0 pa-0",
                                    },
                                    [_vm._v(" " + _vm._s(_vm.time) + " ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "mb-0 pa-0 detail-btn-section",
                      attrs: { cols: "5" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "custom-font-family",
                          attrs: { depressed: "", tile: "", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push({
                                name: "batch-management",
                                params: {
                                  batchId: _vm.batch.batch_id,
                                },
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(!_vm.$language ? "Details" : "বিস্তারিত") +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }