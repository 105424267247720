var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.isOwner
                ? _c("PageTitleSearchCreate", {
                    attrs: {
                      "page-title": !_vm.$language ? "Batches" : "ব্যাচ সমূহ",
                      placeholder: !_vm.$language
                        ? "Search By Batch Name"
                        : "ব্যাচ নাম দিয়ে সার্চ করুন",
                      "create-btn": !_vm.$language
                        ? "Create Batch"
                        : "ব্যাচ তৈরি করুন",
                    },
                    on: {
                      createEvent: function ($event) {
                        return _vm.$router.push("batches/create")
                      },
                    },
                  })
                : _c("PageTitle", {
                    staticClass: "mb-10",
                    attrs: {
                      "page-title": !_vm.$language ? "Batches" : "ব্যাচ সমূহ",
                    },
                  }),
            ],
            1
          ),
          _vm.fetchingBatches
            ? [
                _c("div", { attrs: { id: "loading-wrapper" } }, [
                  _c("div", { attrs: { id: "loading-text" } }, [
                    _vm._v("LOADING"),
                  ]),
                  _c("div", { attrs: { id: "loading-content" } }),
                ]),
              ]
            : _vm.noContentCondition
            ? _c("no-content-component", {
                attrs: { message: _vm.noContentMessage },
              })
            : _vm._l(_vm.batches, function (batch) {
                return _c(
                  "v-col",
                  {
                    key: batch.batch_id,
                    staticClass: "m-4",
                    attrs: { xl: "4", lg: "6", md: "12", sm: "12" },
                  },
                  [
                    _c("MBatchCard", {
                      attrs: { batch: batch },
                      on: { delete: _vm.showDeleteBatchDialog },
                    }),
                  ],
                  1
                )
              }),
        ],
        2
      ),
      _c("dialog-delete", {
        attrs: {
          deleting: _vm.deleting,
          item: _vm.batchToDelete,
          "item-text": !_vm.$language ? "Batch" : "ব্যাচ",
        },
        on: { "confirm-remove": _vm.removeBatch },
        model: {
          value: _vm.batchDeleteDialog,
          callback: function ($$v) {
            _vm.batchDeleteDialog = $$v
          },
          expression: "batchDeleteDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }